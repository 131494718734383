<template>
  <div class="startup_carousel">
    <router-link
      :to="`/startups/${activeStartup.key}`"
      v-touch:swipe.left="previousStartup"
      v-touch:swipe.right="nextStartup"
    >
      <div class="startup_carousel-image_container ">
        <!-- <div class="pattern-wrapper">
          <img src="~/assets/components/page-home/pattern-1.svg" />
        </div> -->

        <div
          v-if="startupsLoaded"
          class="startup_carousel-image_container-icon_wrapper"
          style="left:6rem"
          @click="previousStartup"
        >
          <img
            style="transform: rotate(180deg)"
            src="~/assets/components/page-home/arrow.svg"
            class="startup_carousel-image_container-icon"
          />
        </div>
        <transition name="fade" mode="out-in">
          <img
            v-if="shouldStartupImageBeDisaplyed"
            class="startup_carousel__img startup_carousel-image_container-img"
            :src="require(`@/assets/components/page-home/startups/${activeStartup.imgFile}`)"
            :alt="
              `${activeStartup.name + ': ' + activeStartup.numberOfActiveJobs + ' jobs active'}`
            "
          />
        </transition>
        <div
          v-if="startupsLoaded"
          class="startup_carousel-image_container-icon_wrapper"
          style="right:6rem"
          @click="nextStartup"
        >
          <img
            src="~/assets/components/page-home/arrow.svg"
            class="startup_carousel-image_container-icon"
          />
        </div>
      </div>

      <div class="startup_carousel__band">
        <div class="startup_carousel__content">
          <div class="align-items-center startup_carousel__company">
            <transition name="fade" mode="out-in">
              <img
                v-if="shouldStartupImageBeDisaplyed"
                class="startup_carousel__logo"
                :src="
                  activeStartup
                    ? (activeStartup.logoImage && activeStartup.logoImage.thumbnailSizeUrl) ||
                      activeStartup.extraLogoThumbnailSizeUrl
                    : ''
                "
                :alt="`${activeStartup.name} logo`"
              />
            </transition>
            <transition name="fade" mode="out-in">
              <h3 v-if="shouldStartupImageBeDisaplyed">{{ activeStartup.name }}</h3>
            </transition>
          </div>
          <div class="startup_carousel__counter">
            <transition name="fade" mode="out-in">
              <h3 v-if="shouldStartupImageBeDisaplyed">
                {{ activeStartup.numberOfActiveJobs | pluralize }}
              </h3>
            </transition>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import CompanyModel from '@/models/Company';
import random from 'lodash/random';
import { fetchStartups } from './homePageHelpers';
import AnimatedImageVue from './AnimatedImage.vue';
// import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    prefetchedStartup: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      location: this.$store.getters['meta/sitePreferredCountryCode'],
      startup: undefined,
      startupsLoaded: false,
      shouldStartupImageBeDisaplyed: true,
      startups: []
    };
  },
  async mounted() {
    let homePageStartups = await fetchStartups({ axios: this.$axios });
    this.startups = homePageStartups;
    this.startupsLoaded = true;
    this.changeStartupInterval = setInterval(async () => {
      this.shouldStartupImageBeDisaplyed = false;
      this.startup = await this.getStartup();
      setTimeout(() => {
        this.shouldStartupImageBeDisaplyed = true;
      }, 500);
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.changeStartupInterval);
  },
  computed: {
    activeStartup() {
      return this.startup || this.prefetchedStartup || { logoImage: { thumbnailSizeUrl: '' } };
    }
  },
  methods: {
    touchStartHandler(e) {
      // console.log('touch start');
    },
    touchEndHandler(e) {
      // console.log('touch end');
    },
    previousStartup(e) {
      if (e.preventDefault) {
        e.preventDefault();
        e.stopPropagation();
      }

      this.changeStartup(-1);
    },
    nextStartup(e) {
      if (e.preventDefault) {
        e.preventDefault();
        e.stopPropagation();
      }

      this.changeStartup(1);
    },

    changeStartup(direction) {
      clearInterval(this.changeStartupInterval);
      this.shouldStartupImageBeDisaplyed = false;
      setTimeout(() => {
        this.shouldStartupImageBeDisaplyed = true;
      }, 500);
      const mod = (a, b) => {
        return ((a % b) + b) % b;
        // return a % b === 0 ? 0 : a % b;
      };

      this.startup = this.startups.find((startup) => {
        return startup.index == mod(this.activeStartup.index + direction, this.startups.length);
      });
    },
    async getStartup(location) {
      let matchingStartups = this.startups;
      if (matchingStartups) {
        let index = random(0, matchingStartups.length - 1);

        return matchingStartups[index];
      }
    },
    async init() {
      let startupObj = await this.getStartup(this.location);

      let companyId = startupObj.id;

      try {
        const { data } = await this.$axios.get('companies/id/' + companyId, {
          params: {
            crossplatform: true
          }
        });
        this.startup = {
          ...startupObj,
          ...new CompanyModel(data)
        };
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.startup_carousel {
  z-index: 12;
  max-width: 1800px;
  margin: auto;
  position: relative;

  &__band {
    background-color: rgba($color: #000, $alpha: 0.4);
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 14;
    color: white;
    padding: rem(24px);

    @include media-breakpoint-down(xxl) {
      padding: rem(24px) rem(48px);
    }

    @include media-breakpoint-down(xl) {
      padding: rem(24px) rem(48px);
    }

    @include media-breakpoint-down(lg) {
      padding: rem(24px) rem(72px);
    }

    @include media-breakpoint-down(md) {
      padding: rem(24px) rem(96px);
    }

    @include media-breakpoint-down(sm) {
      padding: rem(16px) rem(88px);
    }

    div {
      display: flex;
    }
  }

  &__company {
    h3 {
      margin-left: rem(24px);

      @include media-breakpoint-down(sm) {
        margin-left: rem(12px);
        font-size: rem(16px);
      }
    }
  }

  &__logo {
    height: rem(72px);
    width: rem(72px);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $white;
    @include media-breakpoint-down(sm) {
      height: rem(40px);
      width: rem(40px);
    }
  }

  &__counter {
    color: #4540de;
    background-color: #ecebfb;
    padding: rem(16px);
    @include media-breakpoint-down(sm) {
      padding: rem(8px);
    }

    h3 {
      margin-bottom: -2px;
      font-size: rem(24px);
      font-weight: 500;

      @include media-breakpoint-down(sm) {
        font-size: rem(16px);
      }
    }
  }
}
.startup_carousel-image_container {
  position: relative;
  width: 100%;
  // padding-bottom: 41.815%; // old
  // padding-bottom: 31.815%; // old
  padding-bottom: 25.815%;
  background-color: #f0f0f0;
  // overflow: hidden;
}

.startup_carousel-image_container-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.startup_carousel-image_container-icon {
  width: 50%;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.startup_carousel-image_container-icon_wrapper {
  position: absolute;
  top: calc(50% - 4rem);
  z-index: 50;
  width: 4.5rem;
  height: 4.5rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  -webkit-box-shadow: 0px 28px 87px -15px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 0px 28px 87px -15px rgba(0, 0, 0, 0.32);
  box-shadow: 0px 28px 87px -15px rgba(0, 0, 0, 0.32);
  > * {
    opacity: 0.5;
    transition: 300ms;
  }
  &:hover {
    > * {
      opacity: 1;
      transition: 300ms;
    }
  }
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.pattern-wrapper {
  width: rem(480px);
  height: rem(400px);
  // width: 300px;
  // height: 300px;
  overflow: hidden;

  position: absolute;
  z-index: 2;

  top: -4.8rem;
  // left: 9rem;
  left: 18%;

  @include media-breakpoint-only(lg) {
    width: rem(380px);
    height: rem(300px);
    left: 13%;
  }

  @include media-breakpoint-only(md) {
    width: rem(280px);
    height: rem(300px);
    left: 8%;
  }

  @include media-breakpoint-down(sm) {
    width: rem(230px);
    height: rem(300px);
    left: 5%;
  }

  img {
    width: 100%;
    height: auto;
  }
}

//////////////////////////////////// animations below
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
